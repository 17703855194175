/* website: 3514-kialaurentides1
 * created at 2021-09-21 09:08 by slachkar*/

// Import all makes and organization styles files
@import "../templates/makes/kia.scss";
@import "../utils/icons.scss";

.widget-sr{
    &.dealer__kia-laurentides{
        .sr-progressBar-bg-title{
            background-color: #37434B;
            &.is-open{
                background-color: #05141F;
                color: #FFFFFF;
            }
        }
        #button-login .label{
            padding: 0 16px 0 0;
        }
        #button-register.sr-button-1{
            color: #FFFFFF;
            background: #37434B;
            font-family: 'KiaSignatureBold', Arial, sans-serif;
            margin-left: 0;
            border: none;
            &:hover{
                text-decoration: none;
                span.label{
                    text-decoration: underline;
                }
            }
            &:before{
                font-family: 'showroom';
                content: '\e9a5';
                transform: rotate(90deg);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
                width: 12px;
                text-decoration: none !important;
            }
        }
    }
}

:lang(fr) .widget-sr{
    &.dealer__kia-laurentides{
        .section-package__title:after{
            content: "Le prix n'inclut pas l'installation.";
            justify-content: center;
            display: flex;
        }
    }
  }

  :lang(en) .widget-sr{
    &.dealer__kia-laurentides{
        .section-package__title:after{
            content: "The price does not include installation.";
            justify-content: center;
            display: flex;
        }
    }
  }